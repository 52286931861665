// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarHeader_title__iGZHl {

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

@media (min-width: 768px) {

    .SidebarHeader_title__iGZHl {

        font-size: 1rem;

        line-height: 1.5rem
    }
}

.SidebarHeader_header__lEAY4 {

    padding-left: 1.5rem;

    padding-right: 1.5rem;

    padding-top: 1rem;

    padding-bottom: 2rem
}

.SidebarHeader_logo__Lc8GL {

    display: flex;

    flex-direction: column;

    row-gap: 0.5rem
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/components/header/SidebarHeader.module.scss"],"names":[],"mappings":"AACE;;IAAA,kBAAA;;IAAA,iBAAA;;IAAA,gBAAA;;IAAA,oBAAA;;IAAA;AAAA;;AAAA;;IAAA;;QAAA,eAAA;;QAAA;IAAA;AAAA;;AAIA;;IAAA,oBAAA;;IAAA,qBAAA;;IAAA,iBAAA;;IAAA;AAAA;;AAIA;;IAAA,aAAA;;IAAA,sBAAA;;IAAA;AAAA","sourcesContent":[".title {\n  @apply text-xs md:text-base text-white font-semibold;\n}\n\n.header {\n  @apply px-6 pt-4 pb-8;\n}\n\n.logo {\n  @apply flex flex-col gap-y-2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SidebarHeader_title__iGZHl`,
	"header": `SidebarHeader_header__lEAY4`,
	"logo": `SidebarHeader_logo__Lc8GL`
};
export default ___CSS_LOADER_EXPORT___;
