import React from 'react';
import Inquiries from './components/Inquiries';
import Container from '../../components/layout/Container';

const InquiriesPage = () => {
  return (
    <Container title="Inquiries">
      <Inquiries />
    </Container>
  );
};

export default InquiriesPage;
