import React from 'react';
import Table from '../../../components/Table/components/Table';
import { Invoices as Service } from '../../../services/Invoices';
import { Link } from 'react-router-dom';

const Invoices = () => {
  const fetchData = params => {
    return Service.all({ params });
  };

const invoiceColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Invoice No',
    dataIndex: 'invoiceNo',
    key: 'invoiceNo',
    sorter: true,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
  },
  {
    title: 'Payment Method',
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: 'Registration ID',
    dataIndex: 'registrationId',
    key: 'registrationId',
    render: (text) => {
      return <Link to={`/registrations/${text}`}>{text}</Link>
    }
  },
  {
    title: 'Created At',
    dataIndex: 'creationDate',
    key: 'creationDate',
    sorter: true,
    render: (text) => {
      return <span>{new Date(text).toLocaleString()}</span>
    }
  },
];

  return (<>
    <Table
        fetchData={fetchData}
        columns={invoiceColumns}
        queryKey="invoices"
        pageSize={20} 
      />
      </>
  );
};

export default Invoices;
