import React from 'react';
import Registrations from './components/Registrations';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Container from '../../components/layout/Container';

const RegistrationsPage = () => {
  const navigate = useNavigate()
  return (
    <Container title="Registrations">
      <Button
          type="primary"
          onClick={() => navigate('/registrations/create')}
          text='New'
        />
      <Registrations />
    </Container>
  );
};

export default RegistrationsPage;
