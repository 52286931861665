import resolveRules from '../../constants/validations';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ConfigProvider, Input, Typography, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { InputTheme } from './Input.theme';
const { Text } = Typography;

function FormInputField({
  name = 'input',
  label,
  form,
  rules,
  labelVariant = 'body2',
  readOnly,
  placeholder = '',
  customRules,
  containersx = {},
  inline = false,
  info, // New prop for info text
  ...props
}) {
  // eslint-disable-next-line
  let { control, formState } = form ? form : useForm();
  const [validations, setValidations] = useState(null);

  useEffect(() => {
    if (rules) setValidations(resolveRules(name, rules));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (customRules) {
      setValidations({ ...validations, ...customRules });
    }
  }, [customRules, validations]);

  const renderLabel = () => (
    <label className="flex items-center">
      {label}
      {info && (
        <Tooltip title={info}>
          <InfoCircleOutlined style={{ marginLeft: '4px', color: '#1890ff' }} />
        </Tooltip>
      )}
    </label>
  );

  return (
    <ConfigProvider theme={InputTheme}>
      <div className='mb-4'>
        {renderLabel()}
        <Controller
          defaultValue={''}
          name={name}
          control={control}
          rules={validations || 'disabled'}
          render={({ field }) => {
            if (props?.type === 'password') {
              return <Input.Password
                id={name}
                variant="outlined"
                placeholder={placeholder}
                readOnly={readOnly}
                {...field}
                {...props}
              />
            }
            return <Input
              id={name}
              variant="outlined"
              placeholder={placeholder}
              readOnly={readOnly}
              {...field}
              {...props}
              autoComplete="off"
            />
          }}
        />
        {formState?.errors[name] && (
          <Text type='danger'><small>{formState?.errors[name]?.message}</small></Text>
        )}
      </div>
    </ConfigProvider>
  );
}

export default FormInputField;
