import { Typography } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import resolveRules from '../../constants/validations';
import ReactSelect from 'react-select';
const { Text } = Typography;

const customStyles = {
  menuPortal: defaultStyles => ({
    ...defaultStyles,
    zIndex: 1000,
  }),
  clearIndicator: prevStyle => ({
    ...prevStyle,
    position: 'absolute',
    right: '12%',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused || state.isActive ? '#66d2df' : '#F7F7F8',
    boxShadow: 'none',
    backgroundColor: '#F7F7F8',
    borderRadius: '8px',
    fontSize: '16px',
    color: '#191919'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? '#66d2df' : '#fff', // Customize hover background color
    fontSize: '14px',
    color: '#191919',
    '&:hover': {
      backgroundColor: '#66d2df', // Customize hover color
    },
  }),
};

function Dropdown({
  name = 'input',
  form,
  rules,
  inDialog = false,
  label,
  hideSelectedOptions = true,
  labelVariant = 'body2',
  options,
  isClearable = true,
  disabled,
  isMulti = false,
  containersx = {},
  ...props
}) {
  // eslint-disable-next-line
  let { control, formState } = form ? form : useForm();
  const [validations, setValidations] = useState(null);

  useEffect(() => {
    if (rules) setValidations(resolveRules(name, rules));
    // eslint-disable-next-line
  }, []);

  return (
    <div className='mb-4'>
      <label>
        {label}
      </label>
        <Fragment>
          <Controller
            defaultValue={null}
            name={name}
            control={control}
            rules={validations || 'disabled'}
            render={({ field }) => (
              <ReactSelect
                {...field}
                hideSelectedOptions={hideSelectedOptions}
                isMulti={isMulti}
                classNamePrefix="custom-select"
                styles={customStyles}
                isClearable={isClearable}
                placeholder="Select"
                {...props}
                options={options}
                isDisabled={disabled}
                aria-autocomplete="list"
                onCreateOption={false}
                menuPortalTarget={
                  inDialog
                    ? document.querySelector('.MuiDialog-root')
                    : document.querySelector('body')
                }
                menuPosition={'fixed'}
                classNames={{
                  control: state => (state.isDisabled ? 'select-disabled' : ''),
                }}
              />
            )}
          />
          {formState?.errors[name] && (
           <Text type='danger'><small>{formState?.errors[name]?.message}</small></Text>
          )}
        </Fragment>
    </div>
  );
}

export default Dropdown;
