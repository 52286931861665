import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField/index.js';
import Button from '../../../components/Button/index.js';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown/index.js';
import Container from '../../../components/layout/Container.jsx';
import { useSaveRegistration } from '../hooks/useSaveRegistration.js';
import { useGetRegistration } from '../hooks/useGetRegistration.js';
import FormDatePicker from '../../../components/FormDatePicker/index.js';
import FormRadioGroup from '../../../components/FormRadioGroup/index.js';
import { convertToISO, convertToSimpleDate } from '../../../utils/dateUtils.js';
import { genderOptions, countryOptions, preferredFootOptions, statusOptions, ageGroupOptions, positionOptions, shirtSizeOptions, preferredPathwayOptions } from '../../../constants/index.js';
import { calculateAge } from '../../../utils/dateUtils.js';
import PaymentDialog from './PaymentDialog.js';
import { useSavePayment } from '../hooks/useSavePayment.js';
import FileUpload from '../../../components/FileUpload/components/FileUpload.js';

const Registration = () => {
  const { id } = useParams();
  const form = useForm();
  const { data } = useGetRegistration(id);
  const navigate = useNavigate();
  const { saveRegistration } = useSaveRegistration('edit', ()=>navigate('/registrations'));
  const { savePayment } = useSavePayment(id, () => {
    setIsPaymentDialogOpen(false);
    navigate('/registrations');
  });
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (data) {
      let formData = {
        ...data,
        birthDate: convertToSimpleDate(data.birthDate),
        preferredPathway: preferredPathwayOptions.find(option => option.value === data.preferredPathway),
        shirtSize: shirtSizeOptions.find(option => option.value === data.shirtSize),
        firstPosition: positionOptions.find(option => option.value === data.firstPosition),
        secondPosition: positionOptions.find(option => option.value === data.secondPosition),
        nationality: countryOptions.find(option => option.value === data.nationality),
        birthPlace: countryOptions.find(option => option.value === data.birthPlace),
        preferredFoot: preferredFootOptions.find(option => option.value === data.preferredFoot),
        status: statusOptions.find(option => option.value === data.status),
        ageGroup: ageGroupOptions.find(option => option.value === data.ageGroup),
        age: calculateAge(data.birthDate),
      };
      setFileList(data.documents);

      delete formData.lastModifiedBy;
      delete formData.lastModifiedDate;
      delete formData.createdBy;
      delete formData.creationDate;
      delete formData.documents;
      form.reset(formData);
    }
  }, [data, form]);

  const getPreparedData = formData => {
    const data = {
      ...formData,
      birthDate: convertToISO(formData.birthDate),
      age: calculateAge(formData.birthDate),
      preferredPathway: formData.preferredPathway?.value,
      shirtSize: formData.shirtSize?.value,
      firstPosition: formData.firstPosition?.value,
      secondPosition: formData.secondPosition?.value,
      nationality: formData.nationality?.value,
      birthPlace: formData.birthPlace?.value,
      preferredFoot: formData.preferredFoot?.value,
      status: formData.status?.value,
      ageGroup: formData.ageGroup?.value,
    };

    if (fileList?.length > 0) {
      data.documents = fileList?.map((file) => {
        return {
          category: "PASSPORT",
          url: file?.url,
          contentType: file?.contentType
        }
      })
    }

    return data;
  };  

  const handleCancel = () => {
    navigate('/registrations')
  };

  const onSave= () => {
    const data = getPreparedData(form.getValues());
    saveRegistration(data);
  };

  const handlePay = (values) => {
    savePayment(values);
    setIsPaymentDialogOpen(false);
  };
  const handleUploadingChange = (uploading) => {
    setIsUploading(uploading);
  };

  return (
    <Container>
      <h1 className="text-2xl font-bold mb-6">Registration</h1>
      <Form onSubmit={onSave} layout="vertical">
        {/* Status Section */}
        <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-gray-800">Status: <span className={`text-[${data?.status === 'UNPAID' ? 'red' : 'green'}]`}>{data?.status}</span></h2>
        </section>

        {/* Personal Information Section */}
        <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Personal Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        <FormInputField
            label="First Name"
            name={'firstname'}
            key={'firstname'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Last Name"
            name={'lastname'}
            key={'lastname'}
            form={form}
            rules="required"
          />
<div className='flex flex-row gap-4'>
<FormRadioGroup 
             name="gender" 
             label="Gender" 
             form={form}
             options={genderOptions}
          />
            <FormDatePicker 
            label="Birth Date" 
            placeholder="Birth Date" 
            name="birthDate" 
            form={form}
          /></div>

          <Dropdown 
             name="birthPlace" 
             key="birthPlace" 
             label="Birth Place" 
             form={form}
             options={countryOptions}
             hideSelectedOptions
          />
         <Dropdown 
             name="nationality" 
             key="nationality" 
             label="Nationality" 
             form={form}
             options={countryOptions}
             hideSelectedOptions
          />

<FormInputField
            label="Passport Number"
            name={'passport'}
            key={'passport'}
            form={form}
          />
<FormInputField
            label="Email"
            name={'email'}
            key={'email'}
            form={form}
            rules="required"
          />
           <FormInputField
            label="Contact Number"
            name={'contactNo'}
            key={'contactNo'}
            form={form}
          />
          <FormInputField
          label="School Year / Grade"
          name={'schoolYear'}
          key={'schoolYear'}
          form={form}
        />
           <FormInputField
          label="School Attending Currently"
          name={'school'}
          key={'school'}
          form={form}
        />

<FormInputField
          label="Curriculum Studying"
          name={'curriculum'}
          key={'curriculum'}
          form={form}
        />
        <FormInputField
          label="Graduation Year From High School"
          name={'highSchoolGraduationYear'}
          key={'highSchoolGraduationYear'}
          form={form}
        />
         <FormInputField
          label="SAT Score"
          name={'satScore'}
          key={'satScore'}
          type="number"
          form={form}
        />
        <FormInputField
          label="Academic Grade"
          name={'academicgrade'}
          key={'academicgrade'}
          type="number"
          form={form}
        />
          </div>
        </section>

        {/* Education Section */}
        <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Other Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
       
        <FormInputField
          label="Club or Academy you played for"
          name={'currentClub'}
          key={'currentClub'}
          form={form}
        />
         <Dropdown
          label="Age Group"
          name={'ageGroup'}
          key={'ageGroup'}
          form={form}
          options={ageGroupOptions}
        />
         <Dropdown
          label="First Choice Position"
          name={'firstPosition'}
          key={'firstPosition'}
          form={form}
          options={positionOptions}
        />
         <Dropdown
          label="Second Choice Position"
          name={'secondPosition'}
          key={'secondPosition'}
          form={form}
          options={positionOptions}
        />
         <FormInputField
          label="Height (cm)"
          name={'height'}
          key={'height'}
          form={form}
        />
         <FormInputField
          label="Weight (kg)"
          name={'weight'}
          key={'weight'}
          form={form}
        />
         <Dropdown
          label="Shirt Size"
          name={'shirtSize'}
          key={'shirtSize'}
          form={form}
          options={shirtSizeOptions}
        />
         <Dropdown 
           name="preferredFoot" 
           label="Preferred Foot" 
           form={form}
           options={preferredFootOptions}
           hideSelectedOptions
        />
        <Dropdown
          label="Preferred Pathway"
          name={'preferredPathway'}
          key={'preferredPathway'}
          form={form}
          options={preferredPathwayOptions}
        />
          </div>
        </section>

        {/* Parent Information Section */}
        <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Parent Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <FormInputField
          label="Parent First Name"
          name={'parentFirstname'}
          key={'parentFirstname'}
          form={form}
        />
        <FormInputField
          label="Parent Last Name"
          name={'parentLastname'}
          key={'parentLastname'}
          form={form}
        />
        <FormInputField
          label="Parent Email"
          name={'parentEmail'}
          key={'parentEmail'}
          form={form}
        />
        <FormInputField
          label="Parent Contact Number + WhatsApp"
          name={'parentContactNo'}
          key={'parentContactNo'}
          form={form}
        />
          </div>
        </section>

        <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Referred By</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          <FormInputField
          label=""
          name={'referredBy'}
          key={'referredBy'}
          form={form}
        />
          </div>
        </section>

       
        {/* Documents Section */}
        <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Passport</h2>
          <FileUpload
            files={fileList}
            name="documents"
            maxLength={1}
            onUploadingChange={handleUploadingChange}
          />
        </section>

          {/* Invoice Details Section */}
          <section className="p-6 bg-white rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-6 text-gray-800">Invoice Details</h2>
          
          <div className="space-y-6 px-12">
            {/* Invoice Header */}
            <div className="flex justify-between items-start border-b pb-4">
              <div>
                <p className="text-sm text-gray-500">Invoice No</p>
                <p className="font-medium">{data?.invoices[0]?.invoiceNo}</p>
              </div>
              <div className="px-3 py-1 rounded-full text-sm" 
                   style={{
                     backgroundColor: data?.paymentStatus === 'PENDING' ? '#FEF3C7' : '#DEF7EC',
                     color: data?.paymentStatus === 'PENDING' ? '#92400E' : '#03543F'
                   }}>
                {data?.invoices[0]?.paymentStatus || 'PENDING'}
              </div>
            </div>

            {/* Package Details */}
            <div className="space-y-4">
              {data?.invoices[0]?.fees?.map((fee, index) => (
                <div key={fee.id} className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">{fee.description}</p>
                    {fee.discountAmount > 0 && <p className="text-sm text-gray-500">{data?.invoices[0]?.promotion?.name}</p>} 
                  </div>
                  <div className="text-right">
                    <p className="font-medium">{fee.amount} AED</p>
                    {fee.discountAmount > 0 && (
                      <p className="text-sm text-green-600">-{fee.discountAmount} AED</p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Total Calculation */}
            <div className="border-t pt-4 space-y-2">
              <div className="flex justify-between font-semibold text-lg">
                <span>Total</span>
                <span>{data?.invoices[0]?.amount} AED</span>
              </div>
            </div>

            {/* Additional Information */}
            <div className="text-sm text-gray-500 space-y-1">
              <p>Created: {new Date(data?.creationDate).toLocaleDateString()}</p>
              <p>Last Modified: {new Date(data?.lastModifiedDate).toLocaleDateString()}</p>
            </div>
          </div>
        </section>

        {/* Form Actions */}
        <div className="flex justify-end space-x-4 mt-8">
          <Button type="default" onClick={handleCancel} text='Cancel'/>
          <Button type="primary" htmlType="submit" text='Save' disabled={isUploading}/>
          {data?.status === 'UNPAID' && <Button type="primary"onClick={() => setIsPaymentDialogOpen(true)} text='Pay'/>} 
        </div>
      </Form>

      <PaymentDialog
        open={isPaymentDialogOpen}
        onClose={() => setIsPaymentDialogOpen(false)}
        onSubmit={handlePay}
        initialValues={{
          invoiceId: data?.invoices[0]?.id,
          invoiceNo: data?.invoices[0]?.invoiceNo,
          paymentMethod: 'BANK_TRANSFER',
          notes: ''
        }}
      />
    </Container>
  );
};

export default Registration;