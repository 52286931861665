import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Auth } from '../../../services/Auth';

export const useChangePassword = (callback) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: changePassword } = useMutation({
    mutationFn: params => Auth.changePassword(params),
    onSuccess: data => {
      let msg = 'Password updated successfully';
      enqueueSnackbar(msg, {
        variant: 'success',
      });
      if (callback) callback();
      if (data.error) {
        enqueueSnackbar(data.result, {
          variant: 'error',
        });
      }
    },
    onError: () => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    changePassword,
  };
};
