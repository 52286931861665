import { useQuery } from 'react-query';
import { Users } from '../../../services/Users';

export const useGetUser = id => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['user', id],
    keepPreviousData: true,
    enabled: !!id && localStorage.getItem('accessToken'),
    queryFn: () => Users.get(id),
    refetchOnWindowFocus: false,
    retry: 2
  });

  return {
    data,
    isLoading,
    isError,
  };
};
