import axios from './axios';

export const Promotions = {
  all: ({ params }, callback) => {
    return axios
      .post(`/api/promotions/search`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data?.data);
        }
        return { ...data?.data };
      })
  },
  get: (id, callback) => {
    return axios
      .get(`/api/promotions/${id}`)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return { ...data.data };
      })
  },
  save: (params, callback) => {
    return axios
      .post(`/api/promotions`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return data.data;
      })
  },
  update: (params, callback) => {
    return axios
      .put(`/api/promotions/${params.id}`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return data.data;
      })
  }
};
