import React, { useEffect, useState } from 'react';
import LoginForm from './components/LoginForm';
import { Alert, Card } from 'antd';
import { useGetLogin } from './hooks/useGetLogin';

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [token, setToken] = useState(false);
  const { data, isError } = useGetLogin(token)

  const handleLogin = (form) => {
    const token = btoa(`${form.username}:${form.password}`);
    setToken(token)
  };

  useEffect(() => {
    if (data) {
      localStorage.setItem('accessToken', data?.token)
      window.location.reload();
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      setToken(false)
      setErrorMessage('Invalid credentials')
    }
  }, [isError])

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f0f2f5'
    }}>
      <Card
        style={{ width: 350, padding: '20px', borderRadius: '8px' }}
        styles={{ padding: 0 }}
      >
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            showIcon
            style={{ marginBottom: '16px' }}
          />
        )}
        <LoginForm onSubmit={handleLogin} />
      </Card>
    </div>
  );
};

export default LoginPage;
