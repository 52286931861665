import { useQuery } from 'react-query';
import { Auth } from '../../../services/Auth';

export const useGetLogin = (params) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['user'],
    keepPreviousData: true,
    queryFn: () => Auth.login(params),
    refetchOnWindowFocus: false,
    enabled: !!params
  });

  return {
    data,
    isLoading,
    isError,
  };
};
