import React, { useState, useEffect } from 'react';
import { Table as AntTable, Button } from 'antd';
import { useQuery } from 'react-query';
import { CSVLink } from "react-csv";
const ReusableTable = ({ fetchData, queryKey, columns, filters }) => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [sortOrder, setSortOrder] = useState({ field: 'id', order: 'ascend' });
  const [csvData, setCsvData] = useState([]);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [queryKey, pagination.current, pagination.pageSize, sortOrder, filters],
    queryFn: () => fetchData({
      filters: filters || [],
      pagination: {
        page: pagination.current - 1,
        size: pagination.pageSize,
        sortBy: sortOrder.field,
        sortDirection: sortOrder.order === 'ascend' ? 'asc' : 'desc',
      },
    }),
    keepPreviousData: true, 
  });

  useEffect(() => {
    const fetchDataForCSV = async () => {
      const dataToExport = await fetchData({
        filters: filters || [],
        pagination: {
          page: 0,
          size: 1000000,
          sortBy: sortOrder.field,
          sortDirection: sortOrder.order === 'ascend' ? 'asc' : 'desc',
        },
      });
      const preparedData = dataToExport?.content?.map(item => {
        const preparedItem = {};
        Object.entries(item).forEach(([key, value]) => {
          if (value === null || value === undefined) {
            preparedItem[key] = '';
          } else if (Array.isArray(value) || typeof value === 'object') {
            preparedItem[key] = JSON.stringify(value);
          } else {
            preparedItem[key] = value;
          }
        });
        return preparedItem;
      });
      setCsvData(preparedData);
    };

    fetchDataForCSV();
  }, [fetchData, filters, sortOrder]);

  const handleTableChange = (
    newPagination,
    filters,
    sorter,
  ) => {
    const sortField = sorter.field || 'id';
    setSortOrder({ field: sortField, order: sorter.order || 'ascend' });

    setPagination({
      ...newPagination,
    });

    refetch();
  };

  const startItem = data?.page?.number * data?.page?.size + 1 || 0;
  let endItem = data?.page?.number * data?.page?.size + data?.page?.size || 0;
  const totalItems = data?.page?.totalElements || 0;
  if(endItem > totalItems) {
    endItem = totalItems;
  }

  return (
    <>
    <div className="flex justify-between">
    <div className="total-data flex items-center">
      Showing {startItem}-{endItem} of {totalItems}
    </div>
    <CSVLink 
      data={csvData} 
      filename={`${queryKey}-${new Date().toISOString().split('T')[0]}.csv`} 
      className='mb-2'
    >
      <Button>Export to CSV</Button>
    </CSVLink>
    </div>
    <AntTable
      className="compact-table my-2"
      columns={columns}
      dataSource={data?.content?.map((item) => ({ ...item, key: item.id }))}
      loading={isLoading}
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: data?.page?.totalElements || 0,
      }}
      onChange={handleTableChange} 
      rowKey={(record) => record.key} 
    />
    </>
  );
};

export default ReusableTable;
