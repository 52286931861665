// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style autofilled input fields */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #F7F7F8 inset !important; /* Custom background color */
  -webkit-text-fill-color: #191919 !important; /* Text color */
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px F7F7F8 inset !important; /* Focused background color */
}`, "",{"version":3,"sources":["webpack://./src/components/FormInputField/styles.scss"],"names":[],"mappings":"AAAA,kCAAA;AACA;EACI,2DAAA,EAAA,4BAAA;EACA,2CAAA,EAAA,eAAA;AACJ;;AAEA;EACI,0DAAA,EAAA,6BAAA;AACJ","sourcesContent":["/* Style autofilled input fields */\ninput:-webkit-autofill {\n    -webkit-box-shadow: 0 0 0px 1000px #F7F7F8 inset !important; /* Custom background color */\n    -webkit-text-fill-color: #191919 !important; /* Text color */\n}\n\ninput:-webkit-autofill:focus {\n    -webkit-box-shadow: 0 0 0px 1000px F7F7F8 inset !important; /* Focused background color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
