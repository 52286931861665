import { brandColors } from "../../styles";
import './styles.scss';
const { text, secondary, grey } = brandColors;


export const InputTheme = {
    token: {
      colorPrimary: secondary, 
      colorBorder: secondary,  // Border color for input fields
      colorText: text,       // Text color
      fontSize: 16,            // Global font size
  },
  components: {
      Input: {
          colorBgContainer: grey.superlight, // Background color for input
          colorBorder: grey.superlight,      // Border color for input
          borderRadius: 8,             // Input border radius
          colorBgContainerFocused: grey.superlight,
          controlHeight: 50,                        // Set custom height for input
          paddingInline: 16,                        // Horizontal padding
          paddingBlock: 8, 
          colorText: text 
      },
  },
};
