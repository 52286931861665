import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';

const LoginForm = ({ onSubmit }) => {
  const form = useForm();

  const onLogin = () => {
    onSubmit(form.getValues());
  };

  return (
    <Form layout="vertical">
        <FormInputField
            label="Username"
            name={'username'}
            key={'loginusername'}
            form={form}
            rules="required"
          />
        <FormInputField type='password' form={form} label="Password" key={'loginpassword'}
            name={'password'} rules="required" />
      <Form.Item>
        <Button
          type="primary"
          block
          onClick={form.handleSubmit(onLogin)}
          text='Login'
        />
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
