import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import resolveRules from '../../constants/validations';
import { DatePicker, Typography } from 'antd';
const { RangePicker } = DatePicker;
const { Text } = Typography

function FormRangePicker({form, name, label, rules, customRules, disabled = false}) {
    let { control, formState } = form ? form : useForm();  // eslint-disable-line
    const [validations, setValidations] = useState(null);

    useEffect(() => {
        if (rules) setValidations(resolveRules(name, rules));
        }, []); // eslint-disable-line

    useEffect(() => {
        if (customRules) {
            setValidations({ ...validations, ...customRules });
        }
    }, [customRules, validations]);

  return (<div className='mb-4'>
    <label>{label}</label>
    <Fragment>
    <Controller
          name={name}
          control={control}
          defaultValue=""
          rules={validations || 'disabled'}
          render={({ field }) => (
            <RangePicker value={field.value} onChange={field.onChange}/>
          )}
        />
        {formState?.errors[name] && (
              <Text type='danger'><small>{formState?.errors[name]?.message}</small></Text>
          )}
      </Fragment>
    </div>
  );
}

export default FormRangePicker