import { useQuery } from 'react-query';
import { Auth } from '../../../services/Auth';

export const useGetProfile = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['profile'],
    keepPreviousData: true,
    enabled: !!localStorage.getItem("accessToken"),
    queryFn: () => Auth.profile(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,  // or some appropriate duration
    cacheTime: Infinity,  
  });

  return {
    data,
    isLoading,
    isError,
  };
};
