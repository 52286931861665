import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useSaveUser } from '../hooks/useSaveUser';
import { useGetUser } from '../hooks/useGetUser';
import Dropdown from '../../../components/Dropdown';
import Drawer from '../../../components/Drawer';
import { SwitchField } from '../../../components/SwitchField/index.js';

const UserDetails = ({open, setOpen, id}) => {
  const form = useForm();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useGetUser(id);
  const { saveUser } = useSaveUser('edit');

  useEffect(() => {
    if (data) {
      let formData = {
        ...data,
        role: { label: data.role?.name, value: data.role?.id }
      };
      form.reset(formData);
    }
  }, [data, form]);

  const enableEditing = () => {
    setIsReadOnly(false);
    setIsEditingEnabled(true);
  };

  const cancelEditing = () => {
    setIsReadOnly(true);
    setIsEditingEnabled(false);
    let formData = {
      ...data,
      role: { label: data.role?.name, value: data.role?.id }
    };
    form.reset(formData);
  };

  const onSaveDetails = () => {
    const formData = form.getValues();
    const data = {
      ...formData,
      role: {
        id: formData.role?.value
      }
    }
    saveUser(data);
    reset();
  };

  const reset = () => {
    setDialogOpen(false);
    setIsReadOnly(true);
    setIsEditingEnabled(false);
  };

  return (
    <Drawer
    title={data?.username} open={open} onClose={() => setOpen(false)} 
    >
    <Form layout="vertical">
        <FormInputField
            label="Username"
            name={'username'}
            key={'username'}
            form={form}
            rules="required"
            readOnly={isReadOnly}
          />
          <FormInputField
            label=""
            name={'password'}
            key={'password'}
            form={form}
            rules="required"
            readOnly={isReadOnly}
            hidden={true}
          />
          <Dropdown 
             name="role" 
             label="Role" 
             form={form}
             rules={'required'}
             options={[{
              label: 'ADMIN',
              value: 1
             }, {
              label: 'AGENT',
              value: 2
             }]}
             hideSelectedOptions
             disabled={isReadOnly}
          />
          <SwitchField
            label="Enabled"
            name="enabled"
            form={form}
          />
      <div className='flex justify-end pb-0 mb-0 gap-6'>
       {!isEditingEnabled && <Button
          onClick={enableEditing}
          text='Edit'
          type="text"
          className="mr-2"
        />}
        {isEditingEnabled && (
          <Button
          type="text"
          onClick={cancelEditing}
          text='Cancel'
        />
        )}
        {isEditingEnabled && (<Button
          type="primary"
          onClick={form.handleSubmit(() => setDialogOpen('save'))}
          text='Save'
          className="mr-2"
        />
        )}
      </div>
    </Form>
          {dialogOpen === 'save' && (
          <Modal
            description={`Are you sure you want to update ${data.username} details?`}
            onConfirm={onSaveDetails}
            onClose={() => setDialogOpen(false)}
          />
        )}
    </Drawer>
  );
};

export default UserDetails;
