import { Button as AntBtn, ConfigProvider } from 'antd';
import { ButtonTheme } from './Button.theme';
function Button({
  text = 'Submit',
  onClick,
  ...props
}) {
 
  const onHandleClick = () => {
    if (typeof onClick == 'function') {
      onClick()
    }
  }

  const typeClass = {
    button: 'px-6 py-5',
    link: 'px-0 py-0 pr-2'
  }

  return (
    <ConfigProvider theme={ButtonTheme}>
        <AntBtn
          onClick={onHandleClick}
          className={typeClass[props?.type] }
          {...props}
        >
          {text}
        </AntBtn>
    </ConfigProvider>
  );
}

export default Button;
