import dayjs from 'dayjs';
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc)

export const convertToISO = (dateString) => {
    if (!dateString) {
        return null
    }
    return dayjs(dateString).startOf('day').toISOString()
  };

export const convertDaysToObj = (selectedDays) => {
    const allDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    const daysObj = allDays.reduce((acc, day) => {
        acc[day] = selectedDays?.includes(day);
        return acc;
    }, {});

    return daysObj;
};

export const convertObjToDaysArray = (daysObj) => {
    return Object.keys(daysObj).filter(day => daysObj[day]);
  };

export const convertToSimpleDate = (isoString) => {
    if (!isoString) {
        return null
    }
    const utcDate = dayjs(isoString).utc(); // Ensure the date is in UTC
    const localDate = utcDate.local(); // Convert to local time
    return dayjs(localDate, 'YYYY-MM-DD')
  };

export const formatDate = (date) => {
    if (!date) {
        return null
    }
    return dayjs(date).format('DD/MM/YYYY')
  };

export const getActiveDays = (obj) => {
    const days = {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday"
    };
  
    // Get the active days
    const activeDays = Object.keys(days).filter(day => obj[day]);
  
    return activeDays.length ? activeDays.map(day => days[day]).join(", ") : null;
  }


  export const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };