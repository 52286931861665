import React, { useState } from 'react';
import Button from '../../components/Button/index.js';
import Container from '../../components/layout/Container.jsx';
import CreatePrice from './components/CreatePrice.js';
import PriceDetails from './components/index.js';
import Prices from './components/Prices.js';
const PricesPage = () => {
  const [openCreate, setOpenCreate] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [id, setId] = useState(null)

  const handleOpenDetails = id => {
    setId(id)
    setOpenDetails(true)
  } 

  return (
    <Container title={'Prices'}>
      <Button
          type="primary"
          onClick={() => setOpenCreate(true)}
          text='New'
        />
      <Prices openDetails={handleOpenDetails} />
      <CreatePrice open={openCreate} setOpen={setOpenCreate}  />
      <PriceDetails open={openDetails} setOpen={setOpenDetails} id={id} />
    </Container>
  );
};

export default PricesPage;
