import React, { useState } from 'react';
import Users from './components/Users';
import Container from '../../components/layout/Container';
import Button from '../../components/Button';
import CreateUser from './components/CreateUser';
import UserDetails from './components';
import ChangePassword from './components/ChangePassword';

const UsersPage = () => {
  const [openCreate, setOpenCreate] = useState(false)
  const [openDetails, setOpenDetails] = useState(false) 
  const [openChangePassword, setOpenChangePassword] = useState(false) 
  const [id, setId] = useState(null);

  const handleOpenDetails = (type, id) => {
    setId(id)
    if (type === 'view') {
      setOpenDetails(true)
    } else {
      setOpenChangePassword(true)
    }
  } 

  return (
    <Container title="Users">
      <Button
          type="primary"
          onClick={() => setOpenCreate(true)}
          text='New'
        />
      <Users openDetails={handleOpenDetails} />
      <CreateUser open={openCreate} setOpen={setOpenCreate}  />
      <UserDetails open={openDetails} setOpen={setOpenDetails} id={id} setId={setId} />
      <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} id={id} setId={setId} />
    </Container>
  );
};

export default UsersPage;
