import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Registrations } from '../../../services/Registrations';

export const useSaveRegistration = (type, callback) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: saveRegistration } = useMutation({
    mutationFn: params => Registrations.save(params),
    onSuccess: data => {
        let msg = 'Registration created successfully';
        if (type === 'edit') {
          msg = 'Registration updated successfully';
        }
        enqueueSnackbar(msg, {
          variant: 'success',
        });
        if (callback) callback();
        client.invalidateQueries(['registrations']);
    },
    onError: () => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    saveRegistration,
  };
};
