import _ from 'lodash';

const defaultRules = ['required', 'minLength', 'maxLength', 'max', 'min'];
const patternRules = [
  'password',
  'email',
  'uaeMobileNumber',
  'alpha',
  'numeric',
  'alphaNumeric',
  'ajmanPlateCode',
  'dubaiPlateCode',
  'uaeCode',
];
export const resolveRules = (field, validations) => {
  const splitRules = validations.split('|');
  let rules = {};
  splitRules.map(rule => {
    const splitRule = rule.split(':');
    const ruleName = splitRule[0];
    const ruleValue = splitRule[1];
    const ruleObj = getValidation(ruleName, field, ruleValue);
    if (defaultRules.includes(ruleName)) {
      rules[ruleName] = ruleObj;
    } else if (patternRules.includes(ruleName)) {
      rules['pattern'] = ruleObj;
    }
    return true;
  });
  return rules;
};

const getValidation = (rule, fieldName, value) => {
  fieldName = _.startCase(fieldName).toLowerCase();
  switch (rule) {
    case 'required':
      return {
        key: 'required',
        value: true,
        message: `The ${fieldName} field is required`,
      };
    case 'minLength':
      return {
        key: 'minLength',
        message: `The ${fieldName} must be at least ${value} characters.`,
        value,
      };
    case 'maxLength':
      return {
        key: 'maxLength',
        message: `The ${fieldName} may not be greater than ${value} characters.`,
        value,
      };
    case 'min':
      return {
        key: 'min',
        message: `The ${fieldName} must be at least ${value}.`,
        value,
      };
    case 'max':
      return {
        key: 'max',
        message: `The ${fieldName} may not be greater than ${value}.`,
        value,
      };
    case 'password':
      return {
        key: 'password',
        message: `The ${fieldName} should have at least one digit [0-9], at least one lowercase letter [a-z], at least one uppercase letter [A-Z], at least one special character [@#$%^&+=], No whitespace allowed`,
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[A-Za-z\d@#$%^&+=]{8,}$/,
      };
    case 'email':
      return {
        key: 'email',
        message: `The ${fieldName} must be a valid email address`,
        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      };
    case 'uaeMobileNumber':
      return {
        key: 'dubaiMobileNumber',
        message: `The ${fieldName} must be a valid UAE phone number (9715XXXXXXXX)`,
        value: /^(?:971)(5[0-9])[0-9]{7}$/,
      };
    case 'uaeCode':
      return {
        key: 'uaeCode',
        message: `The ${fieldName} must be a valid UAE phone number (9715XXXXXXXX)`,
        value: /^(?:971)[0-9]*$/,
      };
    case 'alpha':
      return {
        key: 'alpha',
        message: `The ${fieldName} may only contain letters`,
        value: /^[a-zA-Z]*$/,
      };
    case 'numeric':
      return {
        key: 'numeric',
        message: `The ${fieldName} must be a number`,
        value: /^[0-9]*$/,
      };
    case 'alphaNumeric':
      return {
        key: 'alphaNumeric',
        message: `The ${fieldName} may only contain letters, numbers and special characters (period, comma, underscore, hyphen)`,
        value: /[ء-يA-Za-z0-9 _.-]*[ء-يA-Za-z0-9][ء-يA-Za-z0-9 _.,-]*$/,
      };
    case 'plateCode':
      switch (value) {
        case 'Abu Dhabi':
          return {
            key: 'max',
            message: `The ${fieldName} is not valid`,
            value: 14,
          };
        case 'Ajman':
          return {
            key: 'ajmanPlateCode',
            message: `The ${fieldName} is not valid`,
            value: /^(?:A|B|C|D|E|H?)$/,
          };
        default:
          return {
            key: 'dubaiPlateCode',
            message: `The ${fieldName} is not valid`,
            value: /^(?:A-Z?){7}$/,
          };
      }
    default:
      return {
        key: 'required',
        value: true,
      };
  }
};

export default resolveRules;
