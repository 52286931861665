import React from 'react';
import Invoices from './components/Invoices';
import Container from '../../components/layout/Container';

const InvoicesPage = () => {
  return (
    <Container title="Invoices">
      <Invoices />
    </Container>
  );
};

export default InvoicesPage;
