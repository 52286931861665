import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Steps } from 'antd';
import FormInputField from '../../../components/FormInputField/index.js';
import Button from '../../../components/Button/index.js';
import FileUpload from '../../../components/FileUpload/components/FileUpload.js';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown/index.js';
import Container from '../../../components/layout/Container.jsx';
import { useSaveRegistration } from '../hooks/useSaveRegistration.js';
import FormRadioGroup from '../../../components/FormRadioGroup/index.js';
import FormDatePicker from '../../../components/FormDatePicker/index.js';
import { convertToISO } from '../../../utils/dateUtils.js';
import { genderOptions, preferredFootOptions, countryOptions, ageGroupOptions, positionOptions, shirtSizeOptions, preferredPathwayOptions, packageOptions } from '../../../constants/index.js';
import { calculateAge } from '../../../utils/dateUtils.js';
import PaymentDetails from './PaymentDetails.js';

const CreateRegistration = () => {
  const [current, setCurrent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const form = useForm();
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate()
  const { saveRegistration } = useSaveRegistration('create', (data) => navigate(`/registrations/${data.id}`));

  const getPreparedData = formData => {
    const data = {
      ...formData,
      nationality: formData.nationality?.value,
      preferredFoot: formData.preferredFoot?.value,
      birthDate: convertToISO(formData.birthDate),
      age: calculateAge(formData.birthDate),
      ageGroup: formData.ageGroup?.value,
      firstPosition: formData.firstPosition?.value,
      secondPosition: formData.secondPosition?.value,
      shirtSize: formData.shirtSize?.value,
      preferredPathway: formData.preferredPathway?.value,
      birthPlace: formData.birthPlace?.value,
      status: 'UNPAID',
      documents: fileList?.map((file) => {
        return {
            type: 'PASSPORT',
            url: file?.url,
            contentType: file?.type
          }
      }),
      showcasePackage: formData.showcasePackage?.value,
    };
    delete data.promoCode;
    return data;
  };

  const onPaymentDetailsChange = (data) => {
    form.setValue('payment', data)
  };

  const handleCancel = () => {
    navigate('/registrations')
  };

  const handleUploadingChange = (uploading) => {
    setIsUploading(uploading);
  };

  const steps = [
    {
      title: 'Registration Details',
      content: (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormInputField
            label="First Name"
            name={'firstname'}
            key={'firstname'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Last Name"
            name={'lastname'}
            key={'lastname'}
            form={form}
            rules="required"
          />
          <div className="flex gap-4">
          <FormDatePicker 
            label="Birth Date" 
            placeholder="Birth Date" 
            name="birthDate" 
            form={form}
            rules="required"
          />
          <FormRadioGroup 
             name="gender" 
             label="Gender" 
             form={form}
             options={genderOptions}
             rules="required"
          />
        </div>
        <Dropdown 
             name="nationality" 
             key="nationality" 
             label="Nationality" 
             form={form}
             options={countryOptions}
             hideSelectedOptions
             rules="required"
          />
          <Dropdown 
             name="birthPlace" 
             key="birthPlace" 
             label="Birth Place" 
             form={form}
             options={countryOptions}
             hideSelectedOptions
             rules="required"
          />
          <FormInputField
            label="Passport Number"
            name={'passport'}
            key={'passport'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Email"
            name={'email'}
            key={'email'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Contact Number"
            name={'contactNo'}
            key={'contactNo'}
            form={form}
            rules="required"
          />
           <FormInputField
          label="School Year / Grade"
          name={'schoolYear'}
          key={'schoolYear'}
          form={form}
          rules="required"
        />
           <FormInputField
            label="School Attending Currently"
            name={'school'}
            key={'school'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="High School Graduation Year"
            name={'highSchoolGraduationYear'}
            key={'highSchoolGraduationYear'}
            form={form}
            type="number"
            rules="required"
          />
          <FormInputField
            label="Curriculum Studying"
            name={'curriculum'}
            key={'curriculum'}
            form={form}
            rules="required"
          />
           <FormInputField
            label="SAT Score"
            name={'satScore'}
            key={'satScore'}
            type="number"
            form={form}
          />
          <FormInputField
          label="Academic Grade"
          name={'academicgrade'}
          key={'academicgrade'}
          type="number"
          form={form}
        />
        </div>
      ),
    },
    {
      title: 'Other Information',
      content: (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormInputField
            label="Club or Academy you played for"
            name={'currentClub'}
            key={'currentClub'}
            form={form}
            rules="required"
          />
           <Dropdown 
            label="Age Group"
            name={'ageGroup'}
            key={'ageGroup'}
            form={form}
            options={ageGroupOptions}
            rules="required"
          />
           <Dropdown 
            label="First Choice Position"
            name={'firstPosition'}
            key={'firstPosition'}
            form={form}
            options={positionOptions}
            rules="required"
          />
           <Dropdown 
            label="Second Choice Position"
            name={'secondPosition'}
            key={'secondPosition'}
            form={form}
            options={positionOptions}
            rules="required"
          />
           <FormInputField
            label="Height (cm)"
            name={'height'}
            key={'height'}
            form={form}
            rules="required"
          />
           <FormInputField
            label="Weight (kg)"
            name={'weight'}
            key={'weight'}
            form={form}
            rules="required"
          />
           <Dropdown    
            label="Shirt Size"
            name={'shirtSize'}
            key={'shirtSize'}
            form={form}
            options={shirtSizeOptions}
            rules="required"
          />
           <Dropdown 
             name="preferredFoot" 
             label="Preferred Foot" 
             form={form}
             options={preferredFootOptions}
             hideSelectedOptions
             rules="required"
          />
          <Dropdown 
            label="Preferred Pathway"
            name={'preferredPathway'}
            key={'preferredPathway'}
            form={form}
            options={preferredPathwayOptions}
            rules="required"
          />
          <FormInputField
            label="Parent First Name"
            name={'parentFirstname'}
            key={'parentFirstname'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Parent Last Name"
            name={'parentLastname'}
            key={'parentLastname'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Parent Email"
            name={'parentEmail'}
            key={'parentEmail'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Parent Contact Number"
            name={'parentContactNo'}
            key={'parentContactNo'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Referred By"
            name={'referredBy'}
            key={'referredBy'}
            form={form}
          />
          <div className='flex flex-col gap-2'>
          <label>Passport</label>
           <FileUpload
        key="documents"
        files={fileList}
        onFileChange={setFileList}
        action={`${process.env.REACT_APP_BE_ENDPOINT}/api/files/upload`}
        param={{
          bucketName: "fs-registrations-bucket",
          key: `${form.getValues().email}_${new Date().getTime()}`
        }}
        maxLength={10}
            onUploadingChange={handleUploadingChange}
          />
          </div>
        </div>
      ),
    },
    {
      title: 'Payment Details',
      content: (<PaymentDetails form={form} packages={packageOptions} handleChange={onPaymentDetailsChange} />),
    },
  ];

  const next = async () => {
    const valid = await form.trigger();
    if (valid) {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onPublish = () => {
    const data = getPreparedData(form.getValues());
    saveRegistration(data);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (<Container title="Create Registration">
    <Form layout="vertical" >
    <Steps current={current} items={items} />
      
      <div style={{ marginTop: 24 }}>
        {steps[current].content}
      </div>

      <div className='flex justify-end pb-0 mb-0 mt-2'>
     
        {current === 0 && (
          <Button
          onClick={handleCancel}
          text='Cancel'
          className="mr-2"
        />
        )}

        {current > 0 && (
            <Button
            onClick={prev}
            text='Previous'
            className="mr-2"
            disabled={isUploading}
          />
        )}

        {current < steps.length - 1 && (
            <Button
            type="primary"
            onClick={next}
            text='Next'
            className="mr-2"
            disabled={isUploading}
          />
        )}

        {current === steps.length - 1 && ([
          <Button
          type="primary"
          onClick={form.handleSubmit(onPublish)}
          text='Save'
          className="mr-2"
          disabled={isUploading}
        />]
        )}
      </div>
    </Form>
    </Container>
  );
};

export default CreateRegistration;
