import React from 'react';
import { Modal, Form, Input, Radio } from 'antd';
import { paymentMethods } from '../../../constants';

const PaymentDialog = ({ open, onClose, onSubmit, initialValues }) => {
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      onSubmit({...values, invoiceId: initialValues.invoiceId});
      form.resetFields();
    });
  };

  return (
    <Modal
      title="Payment Details"
      open={open}
      onCancel={onClose}
      onOk={handleSubmit}
      okText="Confirm Payment"
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        className="space-y-4"
      >
        <Form.Item
          name="invoiceNo"
          label="Invoice No"
          className="mb-4"
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="paymentMethod"
          label="Payment Method"
          rules={[{ required: true, message: 'Please select a payment method' }]}
          className="mb-4"
        >
          <Radio.Group className="flex flex-wrap">
            {paymentMethods.map(method => (
              <Radio.Button
                key={method.value}
                value={method.value}
                className="w-1/3 text-center h-20 flex items-center justify-center"
              >
                {method.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="notes"
          label="Notes"
          className="mb-4"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaymentDialog;