import { useQuery } from 'react-query';
import { Registrations } from '../../../services/Registrations';

export const useGetCostEstimates = (params) => {
  return useQuery(
    ['costEstimates', params.showcasePackage, params.promoCode],
    () => Registrations.costEstimates({
      "fees": [
        `${params.showcasePackage.toLowerCase()}-package`,
        "transaction-fee"
      ],
      "promoCode" : params.promoCode
    }),
    {
      // Fetch when either package or promo code exists
      enabled: !!params.showcasePackage || !!(params.showcasePackage && params.promoCode),
      // Prevent unnecessary refetches
      staleTime: 30000, // 30 seconds
      // Don't refetch on window focus
      refetchOnWindowFocus: false,
    }
  );
};
