import React, { useMemo, useCallback } from 'react';
import { AuthPages, RequireAuth, layoutNavMenu, footerNavMenu } from './routes';
import { LayoutProtected } from './components/layout/LayoutProtected';
import { AppLayout } from './components/layout/Layout';
import { Navigate, Route, Routes } from 'react-router-dom';

const App = () => {
  const { data } = {}//useGetProfile();

  const getRoutes = useCallback((allRoutes) => 
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.private) {
          return (
            <Route key={route.key} element={<RequireAuth roles={route.roles} user={data} />}>
              <Route exact path={route.route} element={route.component} />
            </Route>
          );
        }
        if (route.isAuthPage) {
          return (
            <Route key={route.key} element={<AuthPages />}>
              <Route exact path={route.route} element={route.component} />
            </Route>
          );
        }
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    }),
    [data] // Add any dependencies that getRoutes uses from the component scope
  );

  const routes = useMemo(() => getRoutes(layoutNavMenu.concat(footerNavMenu)), [getRoutes]);

  return (
    <LayoutProtected>
      <AppLayout user={data}>
        <Routes>
          {routes}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AppLayout>
    </LayoutProtected>
  );
};

export default App;
