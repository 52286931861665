import { Controller, useForm } from 'react-hook-form';
import { Radio } from 'antd';

function FormRadioGroup({
  name = 'input',
  form,
  label,
  defaultValue = false,
  labelVariant = 'body2',
  options,
  disabled = false,
  containersx = {},
  ...props
}) {
  // eslint-disable-next-line
  let { control } = form ? form : useForm();

  return (
    <div className='mb-4'>
    <label className='block'>
      {label}
    </label>
      <span className='px-4 block'>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <Radio.Group 
              {...field}
              {...props}>
                {options.map(option => (
                   <Radio key={option.value} value={option.value}>{option.label}</Radio>
                ))}
                </Radio.Group>
            );
          }}
        />
      </span>
    </div>
  );
}

export default FormRadioGroup;
