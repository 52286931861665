import { useQuery } from 'react-query';
import { Promotions } from '../../../services/Promotions';

export const useGetPromotion = id => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['promotion', id],
    keepPreviousData: true,
    enabled: !!id,
    queryFn: () => Promotions.get(id),
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
    isError,
  };
};
