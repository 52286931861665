import { Sidebar } from '../sidebar';
import { Button, Layout, theme } from 'antd';
import { footerNavMenu, layoutNavMenu } from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthCheck } from '../../hooks/useAuth';
import { Header } from 'antd/es/layout/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useState } from 'react';

const { Content, Footer } = Layout;

export const AppLayout = ({ children, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const routerBasePath = location.pathname.split('/')[1];
  const isAuthenticated = useAuthCheck();

  const [collapsed, setCollapsed] = useState(false);
  
  const onFooterMenuItemClick = value => {
    if (typeof value == 'function') {
      value()
    } else {
      navigate(value);
    }
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
      <Layout hasSider style={{ minHeight: '100vh' }}>
        {isAuthenticated &&
          <Sidebar
            user={user}
            logo={'/logo.png'}
            title="Dubai Showcase Football"
            selectedMenuItems={[`/${routerBasePath}`]}
            menuItems={layoutNavMenu}
            onMenuItemClick={key => navigate(key)}
            footerMenuItems={footerNavMenu}
            defaultSelectedFooterItem={`/${routerBasePath}`}
            collapsed={collapsed}
            trigger={null}
            collapsible
          />
        }
        <Layout>
        {isAuthenticated &&
        <Header
        className='flex justify-between'
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <div className='flex gap-6 mr-10' >
           {footerNavMenu.map((menuItem) => (
            <div
              data-testid={menuItem.key}
              key={menuItem.key}
              onClick={() => onFooterMenuItemClick(menuItem.route ?? menuItem.onClick)}
            >
              {menuItem.icon}
            </div>
          ))}
        </div>
        </Header>}
          <Content style={{
            margin: '24px 16px 0',
            overflow: 'initial',
          }}>
           {children}</Content>
          {isAuthenticated && <Footer className="bg-white border-t border-t-grey-light py-4 px-8 text-sm">
            <div className="flex justify-between">
              <span>&copy; {new Date().getFullYear()} Dubai Showcase Football. All Rights Reserved</span>
            </div>
          </Footer>}
        </Layout>
      </Layout>
  );
};
