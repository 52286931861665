import React, { useState } from 'react';
import Button from '../../components/Button';
import Container from '../../components/layout/Container';
import CreatePromotion from './components/CreatePromotion';
import PromotionDetails from './components';
import Promotions from './components/Promotions';
const PromotionsPage = () => {
  const [openCreate, setOpenCreate] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [id, setId] = useState(null)

  const handleOpenDetails = id => {
    setId(id)
    setOpenDetails(true)
  } 

  return (
    <Container title={'Promotions'}>
      <Button
          type="primary"
          onClick={() => setOpenCreate(true)}
          text='New'
        />
      <Promotions openDetails={handleOpenDetails} />
      <CreatePromotion open={openCreate} setOpen={setOpenCreate}  />
      <PromotionDetails open={openDetails} setOpen={setOpenDetails} id={id} />
    </Container>
  );
};

export default PromotionsPage;
