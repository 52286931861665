import axios from 'axios';
import { Auth } from './Auth';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BE_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  }
});

instance.interceptors.response.use(
  async res => {
    return res;
  },
  async err => {
    if (err.response) {
      if ([401, 403, 206].includes(err.response.status)) {
          Auth.logout();
      }
    }
    return Promise.reject(err);
  }
);

instance.interceptors.request.use(
  axiosConfig => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axiosConfig.headers['Authorization'] = 'Basic ' + token;
    }
    return axiosConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
