import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Promotions } from '../../../services/Promotions';

export const useUpdatePromotion = (callback) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updatePromotion } = useMutation({
    mutationFn: params => Promotions.update(params),
    onSuccess: data => {
        let msg = 'Promotion updated successfully';
        enqueueSnackbar(msg, {
          variant: 'success',
        });
        client.invalidateQueries(['promotions']);
        if (callback) callback();
    },
    onError: (e) => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    updatePromotion,
  };
};
