import axios from './axios';

export const Inquiries = {
  all: ({ params }, callback) => {
    return axios
      .post(`/api/inquiries/search`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data?.data);
        }
        return { ...data?.data };
      })
  }
}