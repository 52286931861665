import React from 'react';
import Container from '../../components/layout/Container';

const DashboardPage = () => {
  return (
    <Container title="Dashboard">
    
    </Container>
  );
};

export default DashboardPage;
