import React from 'react';
import Table from '../../../components/Table/components/Table';
import Button from '../../../components/Button';
import { Prices as Service } from '../../../services/Prices';
import { InfoCircleOutlined } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';

const Prices = ({openDetails}) => {

  const fetchData = params => {
    return Service.all({ params });
  };

  const pricesColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        if (record.isProtected) {
          return <Tooltip title='Protected tag is system tag and cannot be deleted'>
          <InfoCircleOutlined style={{ marginLeft: '8px' }} />
        </Tooltip>
        } else {
          return [<Button type="link" text="Edit" onClick={() => openDetails(record.id)}/>]
        }
      },
    },
  ];
  return (  
    <Table
        fetchData={fetchData}
        columns={pricesColumns}
        queryKey="prices"
      />
  );
};

export default Prices;
