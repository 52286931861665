import { brandColors } from '../brand-colors/brandColors';
import defaultTheme from 'tailwindcss/defaultTheme';

export const tailwindConfig = {
  content: [],
  theme: {
    extend: {
      colors: brandColors,
      fontFamily: {
        sans: ['Poppins', ...defaultTheme.fontFamily.sans],
      },
    },
  },
};
