import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import 'antd/dist/reset.css';
import './styles/index';

import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LibraryConfigProvider } from './styles/index';


const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={3}>
          <LibraryConfigProvider>
        <App />
        </LibraryConfigProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);