import React, { useEffect, useCallback } from 'react';
import { Spin } from 'antd';
import Dropdown from '../../../components/Dropdown/index.js';
import FormInputField from '../../../components/FormInputField/index.js';
import { useGetCostEstimates } from '../hooks/useGetCostEstimates';
import { debounce } from 'lodash';

const PaymentDetails = ({ form, packages, handleChange }) => {
  const showcasePackage = form.watch('showcasePackage');
  const promoCode = form.watch('promoCode');
  
  // Set default promo code if before Dec 31
  useEffect(() => {
    const today = new Date();
    const decemberEnd = new Date(today.getFullYear(), 11, 31); // Month is 0-based, so 11 is December
    
    if (today <= decemberEnd && !promoCode) {
      form.setValue('promoCode', 'EARLYBIRD2025');
    }
  }, [form]); // Run only once when component mounts

  const [shouldFetch, setShouldFetch] = React.useState(false);

  const debouncedSetShouldFetch = useCallback(
    debounce((value) => {
      setShouldFetch(value);
    }, 3000),
    []
  );

  useEffect(() => {
    if (showcasePackage?.value || promoCode) {
      debouncedSetShouldFetch(true);
    } else {
      setShouldFetch(false);
    }

    return () => debouncedSetShouldFetch.cancel();
  }, [showcasePackage?.value, promoCode, debouncedSetShouldFetch]);

  const { data, isLoading, isError } = useGetCostEstimates({
    showcasePackage: showcasePackage?.value,
    promoCode: promoCode || ''
  }, shouldFetch);


  useEffect(() => {
    if (data) {
      handleChange(data);
    }
  }, [data]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <Dropdown 
        label="Showcase Package"
        name="showcasePackage"
        form={form}
        options={packages}
        rules="required"
      />
      <FormInputField
        label="Promo Code"
        name="promoCode"
        form={form}
        suffix={isLoading && <Spin size="small" />}
      />

      {isLoading && <div>Calculating costs...</div>}
      {isError && <div className="text-red-500">Error fetching cost estimates</div>}
      {data && (
        <div className="col-span-2 p-4 bg-gray-50 rounded-lg">
          <h3 className="font-semibold mb-2">Cost Breakdown</h3>
          <div className="space-y-2">
            {data.fees.map((fee) => (
                <>
              <div className="flex justify-between">
                <span>{fee.name}:</span>
                {fee.discount && <span><strike>{fee.cost} AED</strike> {fee.amount} AED</span>}
                {!fee.discount && <span>{fee.amount} AED</span>}
              </div>
            {fee.discount && (
              <div className="flex justify-between text-green-600">
                <span>Discount: {fee.discount.code}</span>
                  <span>-{fee.discount.discountAmount} AED</span>
                </div>
              )}
            </>
            ))}
            <div className="flex justify-between font-semibold border-t pt-2">
              <span>Total Amount:</span>
              <span>{data.total} AED</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
