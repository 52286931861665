import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Registrations } from '../../../services/Registrations';

export const useSavePayment = (id, callback) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: savePayment } = useMutation({
    mutationFn: params => Registrations.savePayment(id, params),
    onSuccess: data => {
      let msg = 'Payment created successfully';
      enqueueSnackbar(msg, {
        variant: 'success',
      });
      if (callback) callback();
      client.invalidateQueries(['registrations', id, 'registration']);
    },
    onError: () => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    savePayment,
  };
};
