import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';

import { useSaveUser } from '../hooks/useSaveUser';
import Dropdown from '../../../components/Dropdown';
import Drawer from '../../../components/Drawer';
import { SwitchField } from '../../../components/SwitchField/index.js';

const CreateUser = ({open, setOpen}) => {
  const form = useForm({
    defaultValues: {
      enabled: true,
    }
  });
  const navigate = useNavigate()
  const { saveUser} = useSaveUser('create', () => setOpen(false));

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  const handleSave = formData => {
    const data = {
      ...formData,
      role: {
        id: formData.role.value
      },
    };
    saveUser(data);
  };

  const handleCancel = () => {
    navigate('/users')
  };

  return (
    <Drawer title={'Create User'} open={open} onClose={() => setOpen(false)}>
    <Form layout="vertical">
        <FormInputField
            label="Username"
            name={'username'}
            key={'username'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Password"
            name={'password'}
            password={'password'}
            form={form}
            rules="required"
            type='password'
          />
          <Dropdown 
             name="role" 
             label="Role" 
             form={form}
             rules={'required'}
             options={[{
              label: 'Admin',
              value: 1
             }, {
              label: 'Agent',
              value: 2
             }]}
             hideSelectedOptions
          />
          <SwitchField
            label="Enabled"
            name="enabled"
            key="enabled"
            form={form}
          />
      <Form.Item className='grid justify-items-end pb-0 mb-0'>
        <Button
          type="primary"
          onClick={handleCancel}
          text='Cancel'
          className="mr-2"
        />
        <Button
          type="primary"
          onClick={form.handleSubmit(handleSave)}
          text='Save'
          className="mr-2"
        />
      </Form.Item>
    </Form>
    </Drawer>
  );
};

export default CreateUser;
