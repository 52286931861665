import axios from './axios';

export const Users = {
  all: ({ params }, callback) => {
    return axios
      .post(`/api/users/search`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data?.data);
        }
        return { ...data?.data };
      })
  },
  get: (id, callback) => {
    return axios
      .get(`/api/users/${id}`)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return { ...data.data };
      })
  },
  save: (params, callback) => {
    return axios
      .post(`/api/users`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return data.data;
      })
  },
  delete: (id, callback) => {
    return axios
      .delete(`/api/users/${id}`)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return data.data;
      })
  },
};
