import React from 'react';
import Table from '../../../components/Table/components/Table';
import { Inquiries as Service } from '../../../services/Inquiries';


const Inquiries = () => {
  const fetchData = params => {
    return Service.all({ params });
  };

const inquiryColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'First Name',
    dataIndex: 'firstname',
    key: 'firstname',
    sorter: true,
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
    key: 'lastname',
    sorter: true,
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
  },
  {
    title: 'Created At',
    dataIndex: 'creationDate',
    key: 'creationDate',
    sorter: true,
    render: (text) => {
      return <span>{new Date(text).toLocaleString()}</span>
    }
  },
];

  return (<>
    <Table
        fetchData={fetchData}
        columns={inquiryColumns}
        queryKey="inquiries"
        pageSize={20} 
      />
      </>
  );
};

export default Inquiries;
