import { Controller, useForm } from 'react-hook-form';
import { Typography } from 'antd';
import { FormControlLabel, Switch } from '@mui/material';
const { Text } = Typography;

export const SwitchField = ({ 
  name = 'switch',
  form,
  label, 
  checked, 
  onChange, 
  disabled = false,
  ...props
}) => {
  // eslint-disable-next-line
  let { control, formState } = form ? form : useForm();

  return (
    <div className='mb-4'>
         <label>
      {label}
    </label>
    <span className='px-4'>
    <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <FormControlLabel
                control={
                    <Switch
                    checked={field.value}
                    {...field}
                    disabled={disabled}
                    />
                }
                />
     )}
        />
        {formState?.errors[name] && (
              <Text type='danger'><small>{formState?.errors[name]?.message}</small></Text>
          )}
         </span>
      </div>
    )
}
