import React, { useEffect, useState, useCallback } from 'react';
import { PlusOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Upload as AntdUpload, Modal, Tooltip } from 'antd';
import { Upload } from '../../../services/Upload';
import styled from 'styled-components';
import axios from '../../../services/axios';
const StyledUpload = styled(AntdUpload)`
  .ant-upload-select {
    width: 104px !important;
    height: 104px !important;
    margin: 0 8px 8px 0 !important;
  }

  .ant-upload-list-picture-card-container {
    width: 104px !important;
    height: 104px !important;
    margin: 0 8px 8px 0 !important;
  }

  .ant-upload-list-item-container {
    width: 104px !important;
    height: 104px !important;
  }

  .ant-upload-list-item {
    padding: 0 !important;
  }

  .ant-upload-list-item-progress {
    bottom: 0 !important;
    height: 4px !important;
  }

  .ant-upload-list-item-thumbnail {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  .ant-upload-list-item-name {
    display: none;
  }

  .ant-upload-list-item-card-actions {
    opacity: 0;
    transition: all 0.3s;
  }

  .ant-upload-list-item-container:hover .ant-upload-list-item-card-actions {
    opacity: 1;
  }
  .ant-upload-list-item::before {
  width: 100%!important;
  height: calc(100% - 34px) !important;
}
  .ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;
  }

  .ant-upload-list-item-card-actions > a {
    padding: 0 4px;
  }

  .featured-label {
    position: absolute;
    top: -24px;
    left: 12px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 4px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .info-icon {
    cursor: pointer;
    font-size: 14px;
  }

  .custom-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const FileUpload = ({files, onFileChange, maxLength = 20, param, onUploadingChange, disabled = false, showFeaturedLabel = false}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState(files);
  const [uploading, setUploading] = useState({});

  useEffect(() => {
    if (JSON.stringify(files) !== JSON.stringify(fileList)) {
      setFileList(files);
    }
  }, [files, fileList]);

  useEffect(() => {
    const isAnyFileUploading = Object.values(uploading).some(value => value);
    onUploadingChange(isAnyFileUploading);
  }, [uploading, onUploadingChange]);

  const beforeUpload = useCallback((file) => {
    setUploading(prev => ({ ...prev, [file.uid]: true }));
    return true;
  }, []);

  const handleCustomRequest = useCallback(async (options) => {
    const { file, onSuccess, onError, onProgress } = options;

    const data = {
      bucketName: "fs-registrations-bucket",
      key: `${param.key}/${Date.now()}_${file.name}`, 
    };

    try {
      // Simulating upload progress
      let percent = 0;
      const interval = setInterval(() => {
        percent += 10;
        onProgress({ percent });
        if (percent >= 100) {
          clearInterval(interval);
        }
      }, 100);

      const result = await Upload.save({file, data});
      clearInterval(interval);
      onSuccess(result);
    } catch (error) {
      onError(error);
    }
  }, [param?.key]);

  const handlePreview = useCallback(async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  }, []);

  const handleChange = useCallback(({ file, fileList: newFileList }) => {
    if (file.status === 'done' || file.status === 'error') {
      setUploading(prev => ({ ...prev, [file.uid]: false }));
    }
    const updatedFileList = newFileList.map(f => {
      if (f.status === 'done' && f.response && f.response.url) {
        return { ...f, url: f.response.url, contentType: f.response.contentType };
      }
      return f;
    });
    setFileList(updatedFileList);
    onFileChange(updatedFileList);
  }, [onFileChange]);

  const onDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = Number(e.dataTransfer.getData('text/plain'));
    const newFileList = [...fileList];
    const [movedItem] = newFileList.splice(sourceIndex, 1);
    newFileList.splice(targetIndex, 0, movedItem);
    setFileList(newFileList);
    onFileChange(newFileList);
  };

  const handleDownload = async (file) => {
    try {
      const response = await axios.get(`/api/files/download?keyName=${file.url.split('/').pop()}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.url.split('/').pop());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <StyledUpload
        customRequest={handleCustomRequest}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        multiple={maxLength > 1}
        disabled={disabled}
        itemRender={(originNode, file, currFileList) => {
          const index = currFileList.indexOf(file);
          return (
            <div
              draggable
              onDragStart={(e) => onDragStart(e, index)}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, index)}
              style={{ cursor: 'move', width: '100%', height: '100%', position: 'relative' }}
            >
              {index === 0 && showFeaturedLabel && <div className="featured-label">Featured
                <Tooltip title="This image will be displayed as the main image">
                    <InfoCircleOutlined className="info-icon" />
                  </Tooltip>
                </div>}
              <div className="custom-actions">
                <Tooltip title="Download">
                  <DownloadOutlined onClick={() => handleDownload(file)} />
                </Tooltip>
              </div>
              {originNode}
            </div>
          );
        }}
      >
        {fileList.length >= maxLength ? null : uploadButton}
      </StyledUpload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

// Helper function to get base64 of file
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default FileUpload;
