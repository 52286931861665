import styles from './SidebarHeader.module.scss';

export const SidebarHeader = ({ title }) => (
  <div data-testid="sidebar-header" className={styles.header}>
    <a href="/">
      <div className={styles.logo}>
        {title && <span className={styles.title}>{title}</span>}
      </div>
    </a>
  </div>
);
