import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField/index.js';
import Button from '../../../components/Button/index.js';
import Modal from '../../../components/Modal/index.js';
import { useUpdatePrice } from '../hooks/useUpdatePrice.js';
import { useGetPrice } from '../hooks/useGetPrice.js';
import Drawer from '../../../components/Drawer/index.js';
import DropdownField from '../../../components/Dropdown/index.js';
import { priceTypes, currencies } from '../../../constants/index.js';
import createSlug from '../../../utils/createSlug';
const PriceDetails = ({open, setOpen, id}) => {
  const form = useForm();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useGetPrice(id);
  const { updatePrice } = useUpdatePrice(() => setOpen(false)); 
  const name = form.watch('name');
  useEffect(() => {
    if (name) {
      form.setValue("code", createSlug(name));
    }
  }, [name, form]);

  useEffect(() => {
    if (data) {
      let formData = {
        id: data.id,
        name: data.name,
        description: data.description,
        code: data.code,
        cost: data.cost,
        type: priceTypes.find(type => type.value === data.type),
        currency: currencies.find(currency => currency.value === data.currency),
      };
      form.reset(formData);
    }
  }, [data, form]);

  const enableEditing = () => {
    setIsReadOnly(false);
    setIsEditingEnabled(true);
  };

  const cancelEditing = () => {
    setIsReadOnly(true);
    setIsEditingEnabled(false);
    form.reset(data);
  };

  const onSaveDetails = () => {
    const formData = form.getValues();
    formData.type = formData.type.value;
    formData.currency = formData.currency.value;
    updatePrice(formData);
    reset();
  };

  const reset = () => {
    setDialogOpen(false);
    setIsReadOnly(true);
    setIsEditingEnabled(false);
  };

  return (
    <Drawer title={data?.value} open={open} onClose={() => setOpen(false)} >
    <Form layout="vertical">
    <FormInputField
            label="Name"
            name={'name'}
            form={form}
            rules="required"
            disabled={isReadOnly}
          />
           <FormInputField
            label="Code"
            name={'code'}
            form={form}
            rules="required"
            disabled
          />
          <FormInputField
            label="Cost"
            name={'cost'}
            form={form}
            rules="required"
            type="number"
            disabled={isReadOnly}
          />
          <DropdownField
            label="Currency"
            name={'currency'}
            form={form}
            rules="required"
            options={currencies}
            disabled={isReadOnly}
          />
          <DropdownField
              label="Type"
            name={'type'}
            form={form}
            rules="required"
            options={priceTypes}
            disabled={isReadOnly}
          />
          <div className='flex justify-end pb-0 mb-0 gap-6'>
       {!isEditingEnabled && <Button
          onClick={enableEditing}
          text='Edit'
          type="text"
        />}
        {isEditingEnabled && (
          <Button
          type="text"
          onClick={cancelEditing}
          text='Cancel'
        />
        )}
        {isEditingEnabled && (<Button
          type="primary"
          onClick={form.handleSubmit(() => setDialogOpen('save'))}
          text='Save'
        />
        )}
      </div>
    </Form>
          {dialogOpen === 'save' && (
          <Modal
            description={`Are you sure you want to update ${data.name} details?`}
            onConfirm={onSaveDetails}
            onClose={() => setDialogOpen(false)}
          />
        )}
    </Drawer>
  );
};

export default PriceDetails;