import { brandColors } from '../brand-colors/brandColors';

const { teal, grey, red, yellow, green } = brandColors;

export const antdCustomThemeConfig = {
  token: {
    colorPrimary: teal.DEFAULT,
    colorTextDisabled: teal.DEFAULT,
    colorInfo: teal.DEFAULT,
    colorTextBase: grey.DEFAULT,
    colorText: grey.DEFAULT,
    borderRadius: 8,
    fontSize: 16,
    controlHeight: 40,
    colorInfoBg: teal.superlight,
    colorInfoBorder: teal.light,
    colorErrorBg: red.superlight,
    colorErrorBorder: red.light,
    colorWarningBg: yellow.superlight,
    colorWarningBorder: yellow.light,
    colorSuccessBg: green.superlight,
    colorSuccessBorder: green.light,
    colorStepIconActive: teal.DEFAULT
  },
  components: {
    Button: {
      colorPrimary: grey.DEFAULT,
      colorPrimaryHover: grey.medium,
      colorPrimaryActive: grey.DEFAULT,
      colorBgContainerDisabled: grey.light,
      colorTextDisabled: grey.medium,
      defaultBorderColor: grey.DEFAULT,
      fontWeight: 600,
      paddingContentHorizontal: 40,
    },
    Input: {
      colorTextDisabled: grey.DEFAULT,
    },
    Breadcrumb: {
      itemColor: teal.DEFAULT,
      linkColor: teal.DEFAULT,
      linkHoverColor: teal.DEFAULT,
      colorBgTextHover: grey.superlight,
    },
    Badge: {
      colorError: teal.DEFAULT,
    },
    Dropdown: {
      sizePopupArrow: 5,
      fontSizeIcon: 2,
    },
    DatePicker: {
      textHeight: 40,
      cellHeight: 24,
      cellWidth: 36,
      paddingBlock: 0,
      fontWeightStrong: 500,
    },
    Alert: {
      defaultPadding: '21.5px 24px',
    },
    Statistic: {
      colorTextDescription: grey.medium,
    },
    Checkbox: {
      borderRadiusSM: 2,
      controlInteractiveSize: 16,
    },
    Pagination: {
      controlHeight: 32,
      // could not find a way to control the page size changer, added to global styles
    },
    Popover: {
      colorBgElevated: grey.DEFAULT,
      colorText: 'white',
      fontSize: 14,
    },
    Steps: {
      colorTextDescription: grey.medium,
      fontSize: 14,
      fontSizeLabel: 14,
      itemContentFontSize: 14,
      itemTitleFontSize: 14,
      iconSize: 30,
      titleFontSize: 14,
    },
    Table: {
      rowSelectedBg: teal.superlight,
      rowSelectedHoverBg: teal.superlight,
      cellPadding: '12px 12px',
      paddingContentHorizontal: 12,
      paddingContentVertical: 8,
    },
    Tooltip: {
      controlHeight: 32,
    },
    Progress: {
      marginXS: 0,
    },
    // https://www.figma.com/file/awRuCGeuM0WH566DlRihUA/%F0%9F%8D%AD-02-Desktop---Shared-Styles?type=design&node-id=17-5542&mode=design&t=77PKj1yAv5Ehxw8d-0
    Typography: {
      fontSizeHeading1: 36,
      fontSizeHeading2: 24,
      fontSizeHeading3: 20,
      fontSizeHeading4: 18,
      fontSizeHeading5: 16,
    },
    Menu: {
      iconMarginInlineEnd: '0.5rem',
      iconSize: 24,
    },
  },
};
