import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Registrations } from '../../../services/Registrations';

export const useDeleteRegistration = (callback, data) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteRegistration } = useMutation({
    mutationFn: id => Registrations.delete(id),
    onSuccess: () => {
      let msg = `Registration deleted successfully`;
      if (data) {
        msg = `Registration "${data.name}" has beeen deleted successfully`;
      }
      enqueueSnackbar(msg, {
        variant: 'success',
      });
      client.invalidateQueries(['registrations']);
      if (callback) callback();
    },
    onError: () => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    deleteRegistration,
  };
};
