import { Modal as AntModal } from 'antd';
import Loader from '../Loader';

export default function Modal({
  onClose,
  isLoading = false,
  isDeleting = false,
  maxWidth = false,
  fullWidth = false,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  description,
  title,
  form,
  fullScreen = false
}) {
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      if (onClose) {
        onClose();
      }
    }
  };

  const handleSubmit = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <div>
      <AntModal
        title={title}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        className="customModal"
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        keepMounted
        onOk={handleSubmit}
        onCancel={handleClose}
        okText={confirmText}
        cancelText={cancelText}
        fullWidth={fullWidth}>
        {isLoading && <Loader />}
          <div>
            {!isDeleting && description}
            {isDeleting && (
              <span>Deleting</span>
            )}
          {form}
        </div>
      </AntModal>
    </div>
  );
}
