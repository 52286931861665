import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';
import { useSavePromotion } from '../hooks/useSavePromotion';
import Drawer from '../../../components/Drawer';
import DropdownField from '../../../components/Dropdown/index.js';
import { promotionTypes, promotionTargets } from '../../../constants';
import FormRangePicker from '../../../components/FormRangePicker/index.js';
import dayjs from 'dayjs';
import { SwitchField } from '../../../components/SwitchField/index.js';

const CreatePromotion = ({open, setOpen}) => {
  const form = useForm({
    defaultValues: {
      enabled: true,
    }
  });
  const { savePromotion} = useSavePromotion(() => setOpen(false));

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  const handleSave = formData => {
    formData.startDate = formData.dateRange[0];
    formData.endDate = formData.dateRange[1];
    formData.type = formData.type.value;
    formData.target = formData.target.value;
    savePromotion(formData);
  };

  const handleCancel = () => {
    setOpen(false)
  };

  return (
    <Drawer title="Create Promotion" open={open} onClose={handleCancel}>
    <Form layout="vertical">
        <FormInputField
            label="Name"
            name={'name'}
            form={form}
            rules="required"
          />
          <FormInputField
           label="Description"
           name={'description'}
           form={form}
           rules="required"
         />
           <FormInputField
            label="Code"
            name={'code'}
            form={form}
            rules="required"
          />
          <FormInputField
            label="Discount"
            name={'discount'}
            form={form}
            rules="required"
            type="number"
          />
          <DropdownField
            label="Promotion Type"
            name={'type'}
            form={form}
            rules="required"
            options={promotionTypes}
          />
          <DropdownField
            label="Target"
            name={'target'}
            form={form}
            rules="required"
            options={promotionTargets}
          />
          <FormInputField
            label="Max Usage"
            name={'max'}
            form={form}
            rules="required"
            type="number"
          />
           <FormRangePicker 
            label="Start/End Date" 
            name="dateRange" 
            minDate={dayjs()}
            form={form} />
          <SwitchField form={form} label="Enabled" key={'enabled'} name={'enabled'}/>
      <Form.Item className='grid justify-items-end pb-0 mb-0'>
        <Button
          onClick={handleCancel}
          text='Cancel'
          className="mr-2"
        />
        <Button
          type="primary"
          onClick={form.handleSubmit(handleSave)}
          text='Save'
        />
      </Form.Item>
    </Form>
    </Drawer>
  );
};

export default CreatePromotion;