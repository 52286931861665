import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Users } from '../../../services/Users';

export const useSaveUser = (type, callback) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: saveUser } = useMutation({
    mutationFn: params => Users.save(params),
    onSuccess: data => {
        let msg = 'User created successfully';
        if (type === 'edit') {
          msg = 'User updated successfully';
        }
        enqueueSnackbar(msg, {
          variant: 'success',
        });
        if (callback) callback();
        client.invalidateQueries(['users']);
      if (data.error) {
        enqueueSnackbar(data.result, {
          variant: 'error',
        });
      }
    },
    onError: () => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    saveUser,
  };
};
