import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField/index.js';
import Button from '../../../components/Button/index.js';
import { useSavePrice } from '../hooks/useSavePrice.js';
import Drawer from '../../../components/Drawer/index.js';
import DropdownField from '../../../components/Dropdown/index.js';
import { priceTypes, currencies } from '../../../constants/index.js';
import createSlug from '../../../utils/createSlug';

const CreatePrice = ({open, setOpen}) => {
  const form = useForm();
  const { savePrice} = useSavePrice(() => setOpen(false));
  const name = form.watch('name');

  useEffect(() => {
    if (open) {
      form.reset();
    }
  }, [open, form]);

  useEffect(() => {
    if (name) {
      form.setValue("code", createSlug(name));
    }
  }, [name, form]);

  const handleSave = formData => {
    formData.type = formData.type.value;
    formData.currency = formData.currency.value;
    savePrice(formData);
  };

  const handleCancel = () => {
    setOpen(false)
  };

  return (
    <Drawer title="Create Price" open={open} onClose={handleCancel}>
    <Form layout="vertical">
        <FormInputField
            label="Name"
            name={'name'}
            form={form}
            rules="required"
          />
           <FormInputField
            label="Code"
            name={'code'}
            form={form}
            rules="required"
            disabled
          />
          <FormInputField
            label="Cost"
            name={'cost'}
            form={form}
            rules="required"
            type="number"
          />
          <DropdownField
            label="Currency"
            name={'currency'}
            form={form}
            rules="required"
            options={currencies}
          />
          <DropdownField
              label="Type"
            name={'type'}
            form={form}
            rules="required"
            options={priceTypes}
          />
      <Form.Item className='grid justify-items-end pb-0 mb-0'>
        <Button
          onClick={handleCancel}
          text='Cancel'
          className="mr-2"
        />
        <Button
          type="primary"
          onClick={form.handleSubmit(handleSave)}
          text='Save'
        />
      </Form.Item>
    </Form>
    </Drawer>
  );
};

export default CreatePrice;
