import React from 'react';
import Table from '../../../components/Table/components/Table';
import Button from '../../../components/Button';
import { Promotions as Service } from '../../../services/Promotions';
import { InfoCircleOutlined } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import { formatDate } from '../../../utils/dateUtils';

const Promotions = ({openDetails}) => {

  const fetchData = params => {
    return Service.all({ params });
  };

  const promotionsColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      sorter: true,
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      sorter: true,
    },
    {
      title: 'Promotion Type',
      dataIndex: 'promotionType',
      key: 'promotionType',
      sorter: true,
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
      sorter: true,
      render: (text, record) => {
        return record.target || '-'
      }
    },
    {
      title: 'Usage',
      dataIndex: 'currentUsage',
      key: 'currentUsage',
      sorter: true,
      render: (text, record) => {
        return `${record.currentUsage} / ${record.max}`
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      render: (text, record) => {
        return record.startDate ? formatDate(record.startDate) : '-'
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      render: (text, record) => {
        return record.endDate ? formatDate(record.endDate) : '-'
      }
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      key: 'enabled',
      sorter: true,
      render: (text, record) => {
        return record.enabled ? 'Active' : 'Inactive'
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_text, record) => {
        if (record.isProtected) {
          return <Tooltip title='Protected tag is system tag and cannot be deleted'>
          <InfoCircleOutlined style={{ marginLeft: '8px' }} />
        </Tooltip>
        } else {
          return [<Button type="link" text="Edit" onClick={() => openDetails(record.id)}/>]
        }
      },
    },
  ];
  return (
    <Table
        fetchData={fetchData}
        columns={promotionsColumns}
        queryKey="promotions"
      />
  );
};

export default Promotions;