export const brandColors = {
  primary: '#777777',
  secondary: '#66d2df',
  text: '#191919',
  teal: {
    superlight: '#DCF3F5',
    light: '#B9E7EB',
    lighter: '#95DCE1',
    medium: '#72D0D7',
    DEFAULT: '#4FC4CD',
    dark: '#3B939A',
  },
  grey: {
    superlight: '#F7F7F8',
    light: '#EFEFF0',
    lighter: '#ADB2B6',
    regular: '#7E878E',
    medium: '#5B656D',
    DEFAULT: '#323E48',
    dark: '#262F36',
  },
  yellow: {
    superlight: '#FEFADA',
    light: '#FBF4BB',
    medium: '#FAEA6C',
    DEFAULT: '#F6E247',
    dark: '#BAAB35',
    superdark: '#AC8B18',
    extrasuperdark: '#77600F',
  },
  green: {
    superlight: '#CCEFDD',
    light: '#9ADFBA',
    lighter: '#67D098',
    medium: '#35C075',
    DEFAULT: '#02B053',
    dark: '#02843E',
  },
  red: {
    superlight: '#FBD3D3',
    light: '#F7A7A7',
    lighter: '#EE5050',
    medium: '#EE5050',
    DEFAULT: '#EA2424',
    dark: '#AF1B1B',
  },
};
