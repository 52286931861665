import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useUpdatePromotion } from '../hooks/useUpdatePromotion';
import { useGetPromotion } from '../hooks/useGetPromotion';
import Drawer from '../../../components/Drawer';
import DropdownField from '../../../components/Dropdown/index.js';
import { promotionTypes, promotionTargets } from '../../../constants';
import FormRangePicker from '../../../components/FormRangePicker/index.js';
import dayjs from 'dayjs';
import { SwitchField } from '../../../components/SwitchField/index.js';

const PromotionDetails = ({open, setOpen, id}) => {
  const form = useForm();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useGetPromotion(id);
  const { updatePromotion } = useUpdatePromotion(() => setOpen(false)); 

  useEffect(() => {
    if (data) {
      let formData = {
        id: data.id,
        name: data.name,
        description: data.description,
        code: data.code,
        discount: data.discount,
        type: promotionTypes.find(type => type.value === data.promotionType),
        target: promotionTargets.find(target => target.value === data.target),
        max: data.max,
        dateRange: [dayjs(data.startDate), dayjs(data.endDate)],
        enabled: data.enabled
      };
      form.reset(formData);
    }
  }, [data, form]);

  const enableEditing = () => {
    setIsReadOnly(false);
    setIsEditingEnabled(true);
  };

  const cancelEditing = () => {
    setIsReadOnly(true);
    setIsEditingEnabled(false);
    form.reset(data);
  };

  const onSaveDetails = () => {
    const formData = form.getValues();
    formData.startDate = formData.dateRange[0];
    formData.endDate = formData.dateRange[1];
    formData.type = formData.type.value;
    formData.target = formData.target.value;
    updatePromotion(formData);
    reset();
  };

  const reset = () => {
    setDialogOpen(false);
    setIsReadOnly(true);
    setIsEditingEnabled(false);
  };

  return (
    <Drawer title={data?.value} open={open} onClose={() => setOpen(false)} >
    <Form layout="vertical">
        <FormInputField
            label="Name"
            name={'name'}
            form={form}
            rules="required"
            disabled={isReadOnly}
          />
          <FormInputField
           label="Description"
           name={'description'}
           form={form}
           rules="required"
           disabled={isReadOnly}
         />
           <FormInputField
            label="Code"
            name={'code'}
            form={form}
            rules="required"
            disabled={isReadOnly} 
          />
          <FormInputField
            label="Discount"
            name={'discount'}
            form={form}
            rules="required"
            type="number"
            disabled={isReadOnly} 
          />
          <DropdownField
            label="Promotion Type"
            name={'type'}
            form={form}
            rules="required"
            options={promotionTypes}
            disabled={isReadOnly}
          />
          <DropdownField
            label="Target"
            name={'target'}
            form={form}
            rules="required"
            options={promotionTargets}
            disabled={isReadOnly}
          />
          <FormInputField
            label="Max Usage"
            name={'max'}
            form={form}
            rules="required"
            type="number"
            disabled={isReadOnly}
          />
           <FormRangePicker 
            label="Start/End Date" 
            name="dateRange" 
            minDate={dayjs()}
            disabled={isReadOnly} 
            form={form} />
          <SwitchField form={form} label="Enabled" key={'enabled'} name={'enabled'} disabled={isReadOnly}/>
          <div className='flex justify-end pb-0 mb-0 gap-6'>
       {!isEditingEnabled && <Button
          onClick={enableEditing}
          text='Edit'
          type="text"
        />}
        {isEditingEnabled && (
          <Button
          type="text"
          onClick={cancelEditing}
          text='Cancel'
        />
        )}
        {isEditingEnabled && (<Button
          type="primary"
          onClick={form.handleSubmit(() => setDialogOpen('save'))}
          text='Save'
        />
        )}
      </div>
    </Form>
          {dialogOpen === 'save' && (
          <Modal
            description={`Are you sure you want to update ${data.name} details?`}
            onConfirm={onSaveDetails}
            onClose={() => setDialogOpen(false)}
          />
        )}
    </Drawer>
  );
};

export default PromotionDetails;