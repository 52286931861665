import axios from './axios';

export const Auth = {
  login: (token) => {
    return axios
      .get(
        `/auth/login`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${token}`,
          }
        }
      )
      .then(() => {
        return {
          token
        }
      })
  },
  logout: () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
  },
  changePassword: (params, callback) => {
    return axios
      .post(`/auth/change-password`, params)
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return data.data;
      })
  },
  profile: () => {
    return axios
      .get(`/auth/user`)
      .then(({ data }) => {
        return data.data;
      })
  },
};
