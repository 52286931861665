import { ConfigProvider } from 'antd';
import { antdCustomThemeConfig } from '../antd-custom-theme-config/antdCustomThemeConfig';

export const LibraryConfigProvider = ({
  children,
  theme,
}) => {
  return (
    <ConfigProvider theme={antdCustomThemeConfig}>
      {theme && <ConfigProvider theme={theme}>{children}</ConfigProvider>}
      {!theme && children}
    </ConfigProvider>
  );
};
