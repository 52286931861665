import { Layout, Menu } from 'antd';
import { SidebarHeader } from './header/SidebarHeader';

const { Sider } = Layout;

export const Sidebar = ({
  title,
  logo,
  menuItems,
  footerMenuItems,
  selectedMenuItems,
  onMenuItemClick,
  onFooterMenuItemClick,
  defaultSelectedFooterItem,
  noStyle,
  timezone,
  user,
  ...siderProps
}) => {

  const navItems = menuItems.map(element => {
    if (!element.isAuthPage && !!element.label) {
      return {
        key: element.route,
        label: element.label,
        icon: element.icon,
        children: element?.collapse?.map((child) => {
          if (!child.isAuthPage && !!child.label ) {
            return {
              key: child.route,
              label: child.label,
              icon: child.icon,
            }
          }
          return null
        })
      }
    }
    return null
  });
  return (
    <Sider
      data-testid="sidebar"
      breakpoint="md"
      width={200}
      {...siderProps}
    >
        <SidebarHeader logo={logo} title={title} />
          <Menu
            theme="dark"
            selectedKeys={selectedMenuItems}
            mode="inline"
            items={navItems}
            onClick={({ key }) => onMenuItemClick(key)}
          />
    </Sider>
  );
};
