import { brandColors } from "../../styles";
import './styles.scss';
const { secondary } = brandColors;


export const ButtonTheme = {
    token: {
        colorPrimary: secondary, // Custom primary color
        colorLink: secondary, // Custom link color
        colorSuccess: '#52c41a', // Custom success color
        colorError: '#f5222d', // Custom error color
        colorWarning: '#faad14', // Custom warning color
        borderRadius: 4, // Custom border radius
        fontSize: 14, // Custom font size
    },
};