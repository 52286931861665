import { Space } from 'antd';

import { LabelTag } from '../../label-tag/LabelTag';

export const MenuLabel = ({ tagColor = '#70C2CB', tag = '', children }) => {
  return tag ? (
    <Space>
      {children}
      <LabelTag color={tagColor} label={tag} />
    </Space>
  ) : (
    <>{children}</>
  );
};
