import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import DashboardPage from './pages/dashboard';
import { CalendarOutlined, HomeOutlined, LogoutOutlined, TeamOutlined, UserOutlined, MessageOutlined, DollarOutlined, PercentageOutlined } from '@ant-design/icons';
import { MenuLabel } from './components/layout/MenuLabel/MenuLabel';
import LoginPage from './pages/login';
import { Auth } from './services/Auth';
import UsersPage from './pages/users';
import RegistrationsPage from './pages/registrations';
import CreateRegistration from './pages/registrations/components/CreateRegistration';
import CreateUser from './pages/users/components/CreateUser';
import UserDetails from './pages/users/components';
import Registration from './pages/registrations/components/Registration';
import Profile from './pages/users/components/Profile';
import InquiriesPage from './pages/inquiries';
import InvoicesPage from './pages/invoices';
import PromotionsPage from './pages/promotions';
import PricesPage from './pages/prices';
export function RequireAuth({user,roles }) {  
  let location = useLocation();

  if (!localStorage.getItem('accessToken')) {
    return <Navigate to="/login" state={{ from: location }}  />;
  }

  return <Outlet />;
}

export function AuthPages() {
  let location = useLocation();

  if (localStorage.getItem('accessToken')) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
}

export const layoutNavMenu= [
  {
    key: 'login',
    route: '/login',
    icon: <HomeOutlined />,
    label: <MenuLabel>Login</MenuLabel>,
    component: <LoginPage/>,
    private: false,
    isAuthPage: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'dashboard',
    route: '/',
    component: <DashboardPage/>,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'registrations',
    route: '/registrations',
    icon: <CalendarOutlined />,
    label: <MenuLabel>Registrations</MenuLabel>,
    component: <RegistrationsPage/>,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'registrations',
    route: '/registrations/create',
    component: <CreateRegistration/>,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    route: '/registrations/:id',
    component: <Registration />,
    key: 'registrations',
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'inquiries',
    route: '/inquiries',
    icon: <MessageOutlined />,
    label: <MenuLabel>Inquiries</MenuLabel>,
    component: <InquiriesPage />,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'invoices',
    route: '/invoices',
    icon: <DollarOutlined />,
    label: <MenuLabel>Invoices</MenuLabel>,
    component: <InvoicesPage />,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'promotions',
    route: '/promotions',
    icon: <PercentageOutlined />,
    label: <MenuLabel>Promotions</MenuLabel>,
    component: <PromotionsPage />,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },
  {
    key: 'prices',
    route: '/prices',
    icon: <DollarOutlined />,
    label: <MenuLabel>Prices</MenuLabel>,
    component: <PricesPage />,
    private: true,
    roles: ['ADMIN', 'AGENT']
  },

  {
    key: 'users',
    route: '/users',
    icon: <TeamOutlined />,
    label: <MenuLabel>Users</MenuLabel>,
    component: <UsersPage/>,
    private: true,
    roles: ['ADMIN']
  },
  {
    key: 'users',
    route: '/users/create',
    component: <CreateUser/>,
    private: true,
    roles: ['ADMIN']
  },
  {
    route: '/users/:id',
    component: <UserDetails />,
    key: 'users',
    private: true,
    roles: ['ADMIN']
  },
];

export const footerNavMenu = [
  {
    key: 'profile',
    route: '/profile',
    icon: <UserOutlined />,
    label: 'Profile',
    private: true,
    component: <Profile />,
    roles: ['ADMIN', 'AGENT']
  },
  {
    label: 'Logout',
    key: 'logout',
    icon: <LogoutOutlined />,
    private: true,
    onClick: Auth.logout,
    roles: ['ADMIN', 'AGENT']
  },
];