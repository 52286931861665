import { Drawer as AntDrawer } from 'antd';

export default function Drawer({
  children,
  onClose,
  title,
  open
}) {

  const handleClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <div>
      <AntDrawer
        title={title}
        open={open}
        maskClosable={false} 
        onClose={handleClose}>
        {children}
      </AntDrawer>
    </div>
  );
}
