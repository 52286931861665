import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { Users } from '../../../services/Users';

export const useDeleteUser = (callback, data) => {
  const client = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteUser } = useMutation({
    mutationFn: id => Users.delete(id),
    onSuccess: () => {
      let msg = `User deleted successfully`;
      if (data) {
        msg = `User "${data.username}" has beeen deleted successfully`;
      }
      enqueueSnackbar(msg, {
        variant: 'success',
      });
      client.invalidateQueries(['users']);
      if (callback) callback();
    },
    onError: () => {
      enqueueSnackbar('something went wrong', {
        variant: 'error',
      });
    },
  });

  return {
    deleteUser,
  };
};
