import axios from './axios';

export const Upload = {
  save: (params, callback) => {
    const formData = new FormData();
    formData.append('file', params.file);
    
    Object.keys(params.data).forEach((key) => {
      formData.append(key, params.data[key]);
    });

    return axios
      .post(`/api/files/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        if (callback) {
          return callback(data.data);
        }
        return data.data;
      })
  },
};
