import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import FormInputField from '../../../components/FormInputField';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { useChangePassword } from '../hooks/useChangePassword';
import { useGetUser } from '../hooks/useGetUser';
import Drawer from '../../../components/Drawer';

const ChangePassword = ({open, setOpen, id}) => {
  const form = useForm();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data } = useGetUser(id);
  const { changePassword } = useChangePassword();

  useEffect(() => {
    if (data) {
      let formData = {
        username: data.username,
        password: ''
      };
      form.reset(formData);
    }
  }, [data, form]);

  const onSaveDetails = () => {
    const formData = form.getValues();
    changePassword(formData);
  };

  return (
    <Drawer
    title="Change Password" open={open} onClose={() => setOpen(false)} 
    >
    <Form layout="vertical">
        <FormInputField
            label="Username"
            name={'username'}
            key={'username'}
            form={form}
            rules="required"
            readOnly={true}
          />
          <FormInputField type='password' form={form} label="Password" key={'loginpassword'}
            name={'password'} rules="required" />
      <div className='flex justify-end pb-0 mb-0 gap-6'>
          <Button
          type="text"
          onClick={() => setDialogOpen(false)}
          text='Cancel'
        />
        <Button
          type="primary"
          onClick={form.handleSubmit(() => setDialogOpen(true))}
          text='Save'
          className="mr-2"
        />  
      </div>
    </Form>
          {dialogOpen === 'save' && (
          <Modal
            description={`Are you sure you want to update ${data.username}'s password?`}
            onConfirm={onSaveDetails}
            onClose={() => setDialogOpen(false)}
          />
        )}
    </Drawer>
  );
};

export default ChangePassword;
