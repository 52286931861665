import { theme, Typography } from "antd";

const Container = ({children, title}) => {
    const {
        token: { colorBgContainer, borderRadiusLG },
      } = theme.useToken();

      const { Title } = Typography;

    return  <>
    <Title level={3}>{title}</Title>
    <div
    style={{
      padding: 24,
      background: colorBgContainer,
      borderRadius: borderRadiusLG,
      marginBottom: 20
    }}
  >{children}</div></>
}

export default Container;